import React from 'react'
import './CardStack.css'
import creditMachine from '../../images/card-Credit-Machine.png'
import paymentCard from "../../images/Payment-card.png"
import blackCard from "../../images/black-card.png"
const CardStack = () => {
  return (
    <section className='card-stack'>
      <div className="card-stack-heading">
          <div className='heading-small'>
            <span>earn</span>
          <img src={creditMachine} alt="" />
          </div>
          <div className='heading-big'>
            <img src={paymentCard} alt="" />
            <span>This Card Stacks <span className='special-card-text'>GOLD</span></span>
          </div>
      </div>
      <div className="card-stack-body">
         <div className="card-body-l">
          <div className="heading-l">
          <span>Just Swipe</span> <span>& Earn Gold</span>
          </div>
          <div className="text-l">
         <span> Get 0.5-1.5 % Flat Real GOLD every single time you swipe. Gold back is an actual gold reward offered to you when you spend money offline or online  using PayNav’s Neo card.</span>
          </div>
         </div>
         <div className="card-body-r"  data-aos="fade-right">
          <img src={blackCard} alt="" />
         </div>
      </div>
       
    </section>
  )
}

export default CardStack