import React from 'react'
import Body from '../Shopping/Body';
import Home from '../Shopping/Home';
import Saving from '../Saving/Saving';
import MainPage from '../MainPage/MainPage';
import Explore from '../MainPage/Explore';
import Security from '../Shopping/Security';
import Testimonial from '../Testimonial/Testimonial';
import CardStack from '../CardStack/CardStack';
import Gateway from '../Gateway/Gateway';
import Footer from '../Footer/Footer';
const TotalUi = () => {
  return (
    <div>
         <MainPage />
      <Explore />
      <Home />
      <Body />
      <Security />
      <Saving />
      {/* <CardStackBorder /> */}
      <Testimonial />
      <CardStack />
      <Gateway />
      <Footer />
    </div>
  )
}

export default TotalUi