import React from "react";
import "./MainPage.css";
import logo from "../../images/logo.png";
import trolly from "../../images/trolly.png";
import house from "../../images/house.png";
import playStore from "../../images/playstore1.png";
import iosStore from "../../images/ios-icon.png";
import coinMain from "../../images/coin-main.png";
import coinMainR from "../../images/coin-main-r.png";
import paperBag from "../../images/Paperbag.png";
import creditMachine from "../../images/Credit Machine.png";
import goldCoin from "../../images/gold-coin.png";
import blueCoin from "../../images/blue-coin.png";
import sideL from "../../images/side-l.png";
import sideR from "../../images/side-r.png";
import sideB from "../../images/side-b.png";
import menu from "../../images/menu.png";
import { XLg } from "react-bootstrap-icons";
import { useState, useEffect, useRef } from "react";
const MainPage = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const refOne = useRef(null);
  const [dropDown, setDropDown] = useState(false);
  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };
  const toggleNavs = () => {
    // setToggleMenu(!toggleMenu)
    setDropDown(!dropDown);
  };

  const handleClickOutside = (event) => {
    if (
      refOne.current &&
      !refOne.current.contains(event.target) &&
      !event.target.matches(".btn-app")
    ) {
      setDropDown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <>
      <section className="main-page">
        <div>
          <div className="nav-bar">
            <div className="logo-items">
              <div className="logo-img">
                <img src={logo} alt="" />
              </div>
              <span className={!toggleMenu ? "logo-text" : "logo-text-2"}>
                PayNav
              </span>
            </div>
            {(toggleMenu || screenWidth > 700) && (
              <div className="menu-items">
                <div className="list">
                  <li onClick={toggleNav}>
                    <a href="#work">How It works</a>
                  </li>
                  <li onClick={toggleNav}>
                    <a href="mailto:info@paynav.co">Contact Us</a>
                  </li>
                  {screenWidth > 768 ? (
                    <li onClick={toggleNavs} className="btn-app">
                      Get the app{" "}
                      {dropDown && (
                        <div ref={refOne} className="play-store">
                          <div className="play-store-icon">
                            <a
                              href="https://play.google.com/store/apps/details?id=com.paynav.viaflutter.paynav_for_all"
                              target="blank"
                            >
                              <img src={playStore} alt="" />
                            </a>
                          </div>
                          <div className="ios-store">
                            <a
                              href="https://apps.apple.com/us/app/paynav/id1668807648"
                              target="_blank"
                            >
                              <img src={iosStore} alt="" />
                            </a>
                          </div>
                        </div>
                      )}{" "}
                    </li>
                  ) : (
                    <a
                      href="https://paynavforall.page.link/download"
                      target="blank"
                    >
                      <li onClick={toggleNav} className="btn-app">
                        <span>Get the app</span>
                      </li>
                    </a>
                  )}
                </div>
              </div>
            )}
            {!toggleMenu ? (
              <img
                onClick={toggleNav}
                className="menu-icon"
                src={menu}
                alt=""
              />
            ) : (
              <XLg onClick={toggleNav} className="menu-icon text-dark"></XLg>
            )}
          </div>
        </div>

        <div className="bg1"></div>
        <div className="bg2">
          <div className="box-1">
            <div className="trolly-img">
              <img src={trolly} alt="" />
            </div>
            <span>Earn</span>
          </div>
          <div className="box-2">
            <span className="special-text">&</span>
          </div>
          <div className="box-3">
            <span>Save</span>
            <img src={house} alt="" />
          </div>
        </div>
        <div className="bg3">
          <div>
            <div className="gold-text">
              <h1>Real Gold</h1>
              <p>
                Earn Gold every time you shop Save in Gold <br />
                as low as a rupee.
              </p>
            </div>
            <div className="play-store">
              <a
                href="https://play.google.com/store/apps/details?id=com.paynav.viaflutter.paynav_for_all"
                target="blank"
              >
                <div className="play-store-icon">
                  <img src={playStore} alt="" />
                </div>
              </a>
              <a
                href="https://apps.apple.com/us/app/paynav/id1668807648"
                target="_blank"
              >
                <div className="ios-store">
                  <img src={iosStore} alt="" />
                </div>
              </a>
            </div>

            <div className="main-icon">
              <img className="coin-main" src={coinMain} alt="" />
              <img className="coin-main-r" src={coinMainR} alt="" />
              <img src={creditMachine} alt="" className="credit-m" />
              <img src={paperBag} alt="" className="paper-b" />
              <img src={goldCoin} alt="" className="gold-coin" />
              <img src={blueCoin} alt="" className="blue-coin" />
              <img src={sideL} className="side-l" alt="" />
              <img src={sideR} alt="" className="side-r" />
              <img src={sideB} alt="" className="side-b" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MainPage;
