import React from 'react'
import './SimpleCart.css' 
import logo from "../../images/simple-cart-logo.png";
import logoFooter from "../../images/simple-cart-logo-footer.png"
import dwonloadImg from "../../images/pngwing 2.png"
import { useParams } from 'react-router-dom'
import axios from 'axios';
const SimpleCart = () => {
    const params = useParams();
    const token = "a3d23eaf8b9624c146afdf4145e77345871b5163f971e3b217d980a7ea14ae70"
    const getData = () => {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}` 
    }
  return (
   <section className="simple-cart-sec">
   <div className="simple-cart">
   <div className="simple-cart-header">
        <div className="simple-cart-logo">
        <div className="simple-cart-logo-img">
              <img src={logo} alt="" />
            </div>
            <div className="simple-cart-logo-text">
                <span>PayNav</span>
            </div>
        </div>
        <div className="simple-cart-text">
        <div className="simple-cart-header-text1">
            <span>Enter <span className='strong-text'>Myntra</span> Order id below</span>
        </div>
        <div className="simple-cart-header-text2">
            <span>Please enter your order ID without</span>
            <span>any spaces or symbols like ₹ or #</span>
        </div>
        </div>
    </div>
    <div className="simple-cart-body">
        <form action="">
            <div className='input-field'>
            <label htmlFor="">Enter Order/Transaction ID</label>
            <input type="text" placeholder='2002558685'/>
            </div>
            <div className='input-field'>
            <label htmlFor="">Amount</label>
            <input type="text" placeholder='0.00'/>
            </div>
            <div className='input-field'>
            <label htmlFor="">Name of the Product(s)</label>
            <input type="text" placeholder='Adidas Sneakers, Mama Earth Shampoo, Usha Fan etc'/>
            </div>
            <div className="button-cart">
                <button onClick={getData}>Submit</button>
            </div>
        </form>
    </div>
    <div className="simple-cart-footer">
        <div className="logo-footer">
            <img src={logoFooter} alt="" />
        </div>
        <div className="text-footer-simple-cart">
            <span>Win real gold on your everyday shopping at your fave</span>
            <span>brands like food, fashion,groceries and infinity...</span>
        </div>
        <div className="dwonload-btn">
            <div className="dwonload-btn-text">
                <span>Download now: </span>
            </div>
            <div className="dwonload-btn-img">
                <img src={dwonloadImg} alt="" />
            </div>
        </div>
    </div>
   </div>
   </section>
  )
}

export default SimpleCart