import React, {useEffect, useState} from 'react'
import './Body.css'
import img1 from '../../images/image1.png'
import img2 from '../../images/image2.png'
import img3 from '../../images/image3.png'
import paperBag from '../../images/Paperbag-b.png'
import moneyCounting from '../../images/Credit Machine-b.png'
import goldBiscuit from '../../images/gold-bis.png'
import handcoin from '../../images/coin-in-hand.png'
import arrowhand from '../../images/arrow-hand.png'
import goldHand1 from '../../images/gold-hand1.png'
import goldHand2 from '../../images/gold-hand2.png'
import goldHand3 from '../../images/gold-hand3.png'
import ellipse1 from '../../images/Ellipse1.1.png'
import ellipse2 from '../../images/Ellipse1.2.png'
import ellipse3 from '../../images/Ellipse1.3.png'
import ellipse4 from '../../images/Ellipse1.4.png'

const Body = () => {
    const [scrolled, setScrolled] = useState(false);
    useEffect(() => {
        const onScroll = () => {
          if (window.scrollY > 2900 ) {
            setScrolled(true);
          } else {
            setScrolled(false);
          }
        }
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
      }, [])
  return (
   <section className="body-sec" id='work'>
        <div className="body-l" >
            <span>How to EARN?</span>
        </div>
        
<div>
    <div className='lines'>
    <img className='money-counting' src={moneyCounting} alt="" />
        <img className='gold-biscuit' src={goldBiscuit} alt="" />
        <img className='paper-bag' src={paperBag} alt="" />
        <img src={ellipse1} alt="" className="ellipse1-b" />
        <img src={ellipse2} alt="" className="ellipse2-b" />
        <img src={ellipse3} alt="" className="ellipse3-b" />
        <img src={ellipse4} alt="" className="ellipse4-b" />
        <div className='line1' data-aos="fade-right"></div>
        <div className='line2' data-aos="fade-left"></div>
        
        
    </div>
<div className="body-r">
            
            <div className="box box1" data-aos="fade-right">
                <div className='box-img'><img src={img1} alt="" /></div>
                <div>
                    <span>1. Spend Through PayNav</span>
                    <p>More than just shopping, you can earn gold ordering food delivery, riding in an Uber and playing games . </p>
                </div>
            </div>
            <div className="box box2" data-aos="fade-up">
            <div className='box-img'><img src={img2} alt="" /> <img src={goldHand1} alt="" className="gold-hand1" /><img src={goldHand2} alt="" className="gold-hand2" /><img src={goldHand3} alt="" className="gold-hand3" /></div>
                <div>
                    <span>2.Get GOLD Baaaack</span>
                    <p>On every spent amount you get a mentioned percentage % of amount back as digital GOLD. </p>
                </div>
            </div>
            <div className="box box3" data-aos="fade-left" >
            <div className='box-img'><img src={img3} alt="" /> <img className='handcoin' src={handcoin} alt="" /> <img className='arrowhand' src={arrowhand} alt="" /></div>
                <div>
                    <span>3.Creates Investment Portfolio</span>
                    <p>The gold rewards will be added automatically in your investment portfolio. Redeem it  gold coin or cash worth the gold. </p>
                </div>
            </div>
           
        </div>
</div>
   </section>
  )
}

export default Body