import React from 'react'
import './Testimonial.css'
import sirishaImg from '../../images/sirisha.png'
import sameerImg from '../../images/sameer.png'
import shashankImg from '../../images/shashank.png'
import athiraImg from '../../images/athira.png'
import priyaImg from '../../images/priya.png'
import starRating from '../../images/star-rating.png'
import testimonialQuestionmark from '../../images/testimonial-question-mark.png'
const Testimonial = () => {
  return (
   <section className="testimonial">
    <div className="testimonial-heading">
        <div>
            <span>rating</span>
        </div>
        <div>
            <h3>Testimonials</h3>
        </div>
    </div>
    <div className="testimonial-body">
        <div className="upper-testi">
            <div className="user-profile-1">
            <div className="user">
                <div className="user-img">
                    <img src={sirishaImg} alt="" />
                </div>
                <div className="user-details">
                    <div className="name">
                        <span>SIRISHA</span>
                    </div>
                    <div className="profile">
                        <span>CORPORATE EMPLOYEE</span>
                    </div>
                </div>
            </div>
            <div className="statement">
                <p>I had this guilty feeling like what I'm up to in life even after earning a good sum , but i wasn't a girl who's interested on savings and always spending on shopping, food and online. It's really amazing thanks for letting me save some money out of my salary , I'm happy that I've become little responsible in life. Keep up the good work PayNav .</p>
            </div>
            <div className="star">
                <img src={starRating} alt="" />
            </div>
            </div>
            <div className="user-profile-2">
            <div className="user">
                <div className="user-img">
                    <img src={sameerImg} alt="" />
                </div>
                <div className="user-details">
                    <div className="name">
                        <span>SAMEER MD</span>
                    </div>
                    <div className="profile">
                        <span>STUDENT</span>
                    </div>
                </div>
            </div>
            <div className="statement">
                <p>Yet to use this app fully, but from what I've used so far, this seems to be a Awesome app out there! Use the app for most of your day-to-day expenses and get real gold in return! </p>
            </div>
            <div className="star">
                <img src={starRating} alt="" />
            </div>
            </div>
            <div className="user-profile-3">
            <div className="user">
                <div className="user-img">
                    <img src={shashankImg} alt="" />
                </div>
                <div className="star">
                <img src={starRating} alt="" />
            </div>
                
            </div>
            <div className="user-details">
                    <div className="name">
                        <span>SHASHANK</span>
                    </div>
                    <div className="profile">
                        <span>FREELANCE DEVELOPER</span>
                    </div>
                </div>
            <div className="statement">
            <p>It's ideal for folks like me who want to save & earn yet find themselves spending more than they make. It’s easy to use and has a clear user interface.</p>    
            </div>
          
            </div>
            <div className="user-profile-4">
            <div className="user">
                <div className="user-img">
                    <img src={athiraImg} alt="" />
                </div>
                <div className="user-details">
                    <div className="name">
                        <span>ATHIRA</span>
                    </div>
                    <div className="profile">
                        <span>STUDENT</span>
                    </div>
                    <div className="star">
                <img src={starRating} alt="" />
            </div>
                </div>
            </div>
            <div className="statement">
                <p>The app is nice to bring the habit of saving. </p>
            </div>
           
            </div>
            <div className="user-profile-5">
            <div className="user">
                <div className="user-img">
                    <img src={priyaImg} alt="" />
                </div>
                <div className="user-details">
                    <div className="name">
                        <span>PRIYA REDDY</span>
                    </div>
                    <div className="profile">
                        <span>HOUSE WIFE</span>
                    </div>
                    <div className="star">
                <img src={starRating} alt="" />
            </div>
                </div>
            </div>
            <div className="statement">
                <p>The app is awesome. It is like saving & earning while shopping on Ajio. It allows me to do daily savings and investments in a very easy way while shopping. It is a very secure and safe application.</p>
            </div>
           
            </div>
            <div className="user-profile-6">
                <img src={testimonialQuestionmark} alt="" />
            </div>
          
        </div>
        {/* <div className="lower-testi"></div> */}
    </div>
   </section>
  )
}

export default Testimonial