import React from 'react'
import { useParams } from 'react-router-dom'
import './AmazonCart.css' 
import logo from "../../images/simple-cart-logo.png";
import logoFooter from "../../images/simple-cart-logo-footer.png"
import dwonloadImg from "../../images/pngwing 2.png"
import uploadImg from "../../images/export.png"
import { useState } from 'react';

const Amazoncart = () => {
    const [addMore, setAddMore] = useState(1);
    const [item, setItem] = useState(1);
    const addSec = (e) => {
        e.preventDefault();
        setAddMore(addMore+1)
        setItem((prev)=>
            prev+item
        )
    }
    const removeSec = (e) => {
        e.preventDefault();
        setAddMore(addMore-1)
        
    }
    const params = useParams();
    return (
        <section className="simple-cart-sec">
        <div className="simple-cart">
        <span>WebToken: {JSON.stringify(params)}</span>
        <div className="simple-cart-header">
             <div className="simple-cart-logo">
             <div className="simple-cart-logo-img">
                   <img src={logo} alt="" />
                 </div>
                 <div className="simple-cart-logo-text">
                     <span>PayNav</span>
                 </div>
             </div>
             <div className="simple-cart-text">
             <div className="simple-cart-header-text1">
                 <span>Enter <span className='strong-text'>Myntra</span> Order id below</span>
             </div>
             <div className="simple-cart-header-text2">
                 <span>Please enter your order ID without</span>
                 <span>any spaces or symbols like ₹ or #</span>
             </div>
             </div>
         </div>
         <div className="simple-cart-body">
             <form action="">
             <div className='input-field'>
                 <label htmlFor="">Name of the Product(s)</label>
                 <div className='inputs'>
                {
                    [...Array(addMore)].map((e, i) =>  <><input type="text" placeholder='Adidas Sneakers, Mama Earth Shampoo, Usha Fan etc' key={i}/>
                   { addMore===1?<><button onClick={addSec}><span>+</span></button> <span>add more</span></>
                   :<button onClick={removeSec}><span>-</span></button>
                   } </>)
                }
                
                
                 </div>
                 </div>
                 <div className='input-field'>
                 <label htmlFor="">Amount</label>
                 {
                    [...Array(addMore)].map((e, i) =>  <input type="text" placeholder={"0.00  "+ " item  "+ addMore }/>)
                }
                 
                 </div>
                 <div className='input-field'>
                 <label htmlFor="">Invoice Copy</label>
                 {
                    [...Array(addMore)].map((e, i) =>  <div class="upload-btn-wrapper">
                    <button class="btn"><img className='upload-img' src={uploadImg} alt="" /> <span>Click to Upload</span></button>
                    <input type="file" name="myfile" />
                  </div>)
                }
                 
                 </div>
                
                 <div className="button-cart">
                     <button >Submit</button>
                 </div>
             </form>
         </div>
         <div className="simple-cart-footer">
             <div className="logo-footer">
                 <img src={logoFooter} alt="" />
             </div>
             <div className="text-footer-simple-cart">
                 <span>Win real gold on your everyday shopping at your fave</span>
                 <span>brands like food, fashion,groceries and infinity....</span>
             </div>
             <div className="dwonload-btn">
                 <div className="dwonload-btn-text">
                     <span>Download now: </span>
                 </div>
                 <div className="dwonload-btn-img">
                     <img src={dwonloadImg} alt="" />
                 </div>
             </div>
         </div>
        </div>
        </section>
    )
}

export default Amazoncart