import {React, useState, useEffect, useRef} from "react";
import "./Security.css";
import icici from "../../images/icici.png";
import swiss from "../../images/swiss.png";
import lock from "../../images/lock.png";
import lineSecurity from "../../images/Line-security.png";
import paymentCard from "../../images/Payment Card.png"
import creditMachineSec from "../../images/credit-machine-sec.png"
import ellipseSec1 from "../../images/Ellipse-sec-1.png"
import ellipseSec2 from "../../images/Ellipse-sec-2.png"
import ellipseSec3 from "../../images/Ellipse-sec-3.png"
import goldCoinSec from "../../images/gold-coin-sec.png"
import playStore from "../../images/playstore1.png";
import iosStore from "../../images/ios-icon.png"
const Security = () => {
  const refOne = useRef(null)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [dropDown, setDropDown] = useState(false)
  const toggleNavs = () => {
    setDropDown(!dropDown)
  }

  const handleClickOutside = (event) => {
    if (refOne.current && !refOne.current.contains(event.target) && !event.target.matches('.sec-btn')) {
      setDropDown(false);
    }

  }
  useEffect(()=>{
    document.addEventListener("click", handleClickOutside, true)
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', changeWidth)

    return () => {
      window.removeEventListener('resize', changeWidth);
      document.removeEventListener('click', handleClickOutside, true);
  }
  })
  return (
    <section className="security">
      <div className="sec-img">
        <div className="payment-card-img">
          <img src={paymentCard} alt="" />
        </div>
        <div className="credit-machine-sec">
          <img src={creditMachineSec} alt="" />
        </div>
        <div className="ellipse-sec-1">
          <img src={ellipseSec1} alt="" />
        </div>
        <div className="ellipse-sec-2">
          <img src={ellipseSec2} alt="" />
        </div>
        <div className="ellipse-sec-3">
          <img src={ellipseSec3} alt="" />
        </div>
        <div className="gold-coin-sec">
          <img src={goldCoinSec} alt="" />
        </div>
      </div>
      <div className="security-heading">
        <h3>Security</h3>
        <div className="head">
          <h1>YOUR</h1>
          <div className="line-secq">
            <img src={lineSecurity} alt="" />
          </div>
          <div className="head-r">
            <span>Card</span>
            <span>Data</span>
            <span>24k GOLD</span>
          </div>
        </div>
      </div>
      <div className="security-body">
        <div className="sec-body-l">
          <div className="sec-body-l-img">
            <img className="icici" src={icici} alt="" />
            <img className="swiss" src={swiss} alt="" />
          </div>
          <div className="sec-body-l-txt">
           <div className="txt-lock">
           <span> Are Safe </span>
           <img src={lock} alt="" />
           </div>
           <div> <span>With Trusted</span></div>
            <div><span>Partners For</span></div>
           <div> <span>a Different Kind</span></div>
            <div><span>of Banking</span></div>
          </div>
        </div>
        <div className="sec-body-r">
            <div>
                <p>PayNav gurantees that all your personal data and transactions are encrypted, and secured. The gold you buy online is allocated as physical gold under your direct ownership and stored within fully insured, certified vaults located on our partners highly secure premises.The prepaid card issued is partnered with a RBI approved bank and you get to have the same bank level security with us.</p>
            </div>
           {screenWidth>768 ?<button onClick={toggleNavs} className="sec-btn">Discover {dropDown && (
                  <div ref={refOne} className="play-store">
                  <div className="play-store-icon">
                  <a href="https://play.google.com/store/apps/details?id=com.paynav.viaflutter.paynav_for_all" target="blank"><img src={playStore} alt="" /></a>
                  </div>
                  <div className="ios-store">
                    <a href="https://apps.apple.com/us/app/paynav/id1668807648" target="_blank"><img src={iosStore} alt="" /></a>
                  </div>
                  </div>
      )}</button> : <a href="https://paynavforall.page.link/download" target="_blank" rel="noopener noreferrer"> <button className="sec-btn"><span>Discover</span></button></a>}
           
        </div>
      </div>
    </section>
  );
};

export default Security;
