import './App.css';
import TotalUi from './Component/FullUI/TotalUi';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SimpleCart from './Component/Simple-cart/SimpleCart';
import Amazoncart from './Component/Simple-cart/AmazonCart';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
        <Route exact path="/" element={<TotalUi />} />
        <Route path="/ticket/:id" element={<SimpleCart />} />
        <Route path="/ticketa/:id" element={<Amazoncart />} />
        </Routes>
      </BrowserRouter>
     
    </div>
  );
}

export default App;
