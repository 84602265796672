import React, {useEffect, useRef, useState} from "react";
import "./Home.css";
import Aos from "aos"
import "aos/dist/aos.css"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import gold from '../../images/gold.png'
import swiggy from '../../images/swiggy.png'
import zivame from '../../images/z.png'
import bigBasket from '../../images/bn.png'
import flipcart from '../../images/flipcart.png'
import makeMyTrip from '../../images/my.png'
import myntra from '../../images/myntra.png'
import ajio from '../../images/ajio.png'
import agoda from '../../images/agoda.png'
import apolo from '../../images/apolo.png'
import amazon from '../../images/amazon.png'
import beard from '../../images/beard.png'
import boat from '../../images/boat.png'
import cad from '../../images/cad.png'
import cutfit from '../../images/cutfit.png'
import fernsn from '../../images/fernsn.png'
import firstCry from '../../images/first-cry.png'
import muscle from '../../images/muscle.png'
import peeSafe from '../../images/pee-safe.png'
import sanfe from '../../images/sanfe.png'
import tanishq from '../../images/tanishq.png'
import uber from '../../images/uber.png'
import udemy from '../../images/udemy.png'
import ellipse1 from '../../images/Ellipse1.1.png'
import ellipse2 from '../../images/Ellipse1.2.png'
import ellipse3 from '../../images/Ellipse1.3.png'
import ellipse4 from '../../images/Ellipse1.4.png'
import playStore from "../../images/playstore1.png";
import iosStore from "../../images/ios-icon.png"
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1250 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1250, min: 1050 },
    items: 5,
  },
  miniTablet: {
    breakpoint: { max: 1050, min: 850 },
    items: 4,
  },
  miniTablet1: {
    breakpoint: { max: 850, min: 600 },
    items: 3,
  },
  bigMobile: {
    breakpoint: { max: 600, min: 377 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 377, min: 270 },
    items: 2,
  },
  smallMobile: {
    breakpoint: { max: 277, min: 0 },
    items: 1,
  },
};

const Home = () => {
  const refOne = useRef(null)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [dropDown, setDropDown] = useState(false)
  const toggleNavs = () => {
    setDropDown(!dropDown)
  }

  const handleClickOutside = (event) => {
    if (refOne.current && !refOne.current.contains(event.target) && !event.target.matches('.dis-btn')) {
      setDropDown(false);
    }

  }
  useEffect(()=>{
    document.addEventListener("click", handleClickOutside, true)
    Aos.init({duration:1000});
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', changeWidth)

    return () => {
      window.removeEventListener('resize', changeWidth);
      document.removeEventListener('click', handleClickOutside, true);
  }
  })
  return (
    <div className="home-sec">
      <div className="ellipse">
        <img className="img-ellipse1" src={ellipse1} alt="" />
        <img className="img-ellipse2" src={ellipse2} alt="" />
        <img className="img-ellipse3" src={ellipse4} alt="" />
        <img className="img-ellipse4" src={ellipse3} alt="" />
        <div className="overlay"></div>
      </div>
      <div className="bg"></div>
      <div className="heading shopping-head" data-aos="fade-right">
        <span>Earn</span>
        <h1>Shopping is <br class="d-md-none"></br> investing</h1>
      </div>
      <div className="least-inner-box">
        
      <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={1000}
            removeArrowOnDeviceType={[
              "tablet",
              "miniTablet",
              "mobile",
              "desktop",
              "superLargeDesktop",
              "miniTablet1",
              "smallMobile",
              "bigMobile"

            ]}
            className="owl-carousel owl-theme slider"
          >
            <div className="item">
              <div className="carousel-img">
              <img className="swiggy" src={swiggy} alt="" />
              </div>
              <div>
                <span>Swiggy</span>
                <p>upto 3% <img src={gold} alt="" /> gold</p>
              </div>
            </div>
            
            <div className="item">
              <div className="carousel-img">
              <img className="flipcart" src={flipcart} alt="" />
              </div>
              <div>
                <span>Flipcart</span>
                <p>upto 7% <img src={gold} alt="" /> gold</p>
              </div>
            </div>
            
            <div className="item">
              <div className="carousel-img">
              <img className="mmt" src={makeMyTrip} alt="" />
              </div>
              <div>
                <span>Book My Show</span>
                <p>upto 14% <img src={gold} alt="" /> gold</p>
              </div>
            </div>
            
            <div className="item">
              <div className="carousel-img">
              <img className="myntra" src={myntra} alt="" />
              </div>
              <div>
                <span>Myntra</span>
                <p>upto 7.5% <img src={gold} alt="" /> gold</p>
              </div>
            </div>
            
            <div className="item">
              <div className="carousel-img">
              <img className="bb" src={bigBasket} alt="" />
              </div>
              <div>
                <span>Big Basket</span>
                <p>upto 14% <img src={gold} alt="" /> gold</p>
              </div>
            </div>
            <div className="item">
              <div className="carousel-img">
              <img className="z" src={zivame} alt="" />
              </div>
              <div>
                <span>Zivame</span>
                <p>upto 11.2% <img src={gold} alt="" /> gold</p>
              </div>
            </div>
            <div className="item">
              <div className="carousel-img">
              <img className="ajio" src={ajio} alt="" />
              </div>
              <div>
                <span>Ajio</span>
                <p>upto 7% <img src={gold} alt="" /> gold</p>
              </div>
              </div>
            <div className="item">
              <div className="carousel-img">
              <img className="amazon" src={amazon} alt="" />
              </div>
              <div>
                <span>Amazon</span>
                <p>upto 6% <img src={gold} alt="" /> gold</p>
              </div>
            </div>
            <div className="item">
              <div className="carousel-img">
              <img className="beard" src={beard} alt="" />
              </div>
              <div>
                <span>Beardo</span>
                <p>upto 15% <img src={gold} alt="" /> gold</p>
              </div>
            </div>
            <div className="item">
              <div className="carousel-img">
              <img className="boat" src={boat} alt="" />
              </div>
              <div>
                <span>Boat</span>
                <p>upto 8% <img src={gold} alt="" /> gold</p>
              </div>
            </div>
            <div className="item">
              <div className="carousel-img">
              <img className="cad" src={cad} alt="" />
              </div>
              <div>
                <span>Cadbury</span>
                <p>upto 36% <img src={gold} alt="" /> gold</p>
              </div>
            </div>
            <div className="item">
              <div className="carousel-img">
              <img className="cutfit" src={cutfit} alt="" />
              </div>
              <div>
                <span>Cult Fit</span>
                <p>upto 12% <img src={gold} alt="" /> gold</p>
              </div>
            </div>
            <div className="item">
              <div className="carousel-img">
              <img className="fernsn" src={fernsn} alt="" />
              </div>
              <div>
                <span>Ferns n Petals</span>
                <p>upto 6.8% <img src={gold} alt="" /> gold</p>
              </div>
            </div>
            <div className="item">
              <div className="carousel-img">
              <img className="firstCry" src={firstCry} alt="" />
              </div>
              <div>
                <span>First Cry</span>
                <p>upto 3.5% <img src={gold} alt="" /> gold</p>
              </div>
            </div>
            <div className="item">
              <div className="carousel-img">
              <img className="muscle" src={muscle} alt="" />
              </div>
              <div>
                <span>Muscle Blaze</span>
                <p>upto 3% <img src={gold} alt="" /> gold</p>
              </div>
            </div>
            <div className="item">
              <div className="carousel-img">
              <img className="peeSafe" src={peeSafe} alt="" />
              </div>
              <div>
                <span>Pee Safe</span>
                <p>upto 38.2% <img src={gold} alt="" /> gold</p>
              </div>
            </div>
            <div className="item">
              <div className="carousel-img">
              <img className="sanfe" src={sanfe} alt="" />
              </div>
              <div>
                <span>Sanfe</span>
                <p>upto 17% <img src={gold} alt="" /> gold</p>
              </div>
            </div>
            <div className="item">
              <div className="carousel-img">
              <img className="tanishq" src={tanishq} alt="" />
              </div>
              <div>
                <span>Tanishq</span>
                <p>upto 3% <img src={gold} alt="" /> gold</p>
              </div>
            </div>
            <div className="item">
              <div className="carousel-img">
              <img className="uber" src={uber} alt="" />
              </div>
              <div>
                <span>Uber</span>
                <p>upto 3% <img src={gold} alt="" /> gold</p>
              </div>
            </div>
            <div className="item">
              <div className="carousel-img">
              <img className="udemy" src={udemy} alt="" />
              </div>
              <div>
                <span>Udemy</span>
                <p>upto 11% <img src={gold} alt="" /> gold</p>
              </div>
            </div>
            <div className="item">
              <div className="carousel-img">
              <img className="agoda" src={agoda} alt="" />
              </div>
              <div>
                <span>Agoda</span>
                <p>upto 3.2% <img src={gold} alt="" /> gold</p>
              </div>
            </div>
            <div className="item">
              <div className="carousel-img">
              <img className="apolo" src={apolo} alt="" />
              </div>
              <div>
                <span>Apollo Pharmacy</span>
                <p>upto 11% <img src={gold} alt="" /> gold</p>
              </div>
            </div>
            
          </Carousel>
      </div>
      <div className="inner-box">
        <div className="text" data-aos="fade-right">
          <h1>500 <span>+</span> Brands have <br /> become our merchants </h1>
        </div>
        <div className="text-2" data-aos="fade-left">
            <p>More than just shopping, you can earn gold ordering food delivery, riding in an Uber and playing games . With so many ways to win gold, they’ll add up quickly.</p>
           {screenWidth>768 ? <button onClick={toggleNavs}className="dis-btn">Discover{dropDown && (
                  <div ref={refOne} className="play-store">
                  <div className="play-store-icon">
                  <a href="https://play.google.com/store/apps/details?id=com.paynav.viaflutter.paynav_for_all" target="blank"><img src={playStore} alt="" /></a>
                  </div>
                  <div className="ios-store">
                    <a href="https://apps.apple.com/us/app/paynav/id1668807648" target="_blank"><img src={iosStore} alt="" /></a>
                  </div>
                  </div>
      )}</button> : <a href="https://paynavforall.page.link/download" target="blank"><button className="dis-btn"><span>Discover</span></button></a>}
        </div>
      </div>
    </div>
  );
};

export default Home;
