import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import footerLogo from "../../images/footer-logo.png";
import insta from "../../images/instagram.png";
import twitter from "../../images/coolicon.png";
import facebook from "../../images/facebook.png";
import linkedin from "../../images/linkedin.png";
// import telegram from "../../images/telegram-logo.png";
import sideLine1 from "../../images/Ellipsef1.png";
import sideLine2 from "../../images/Ellipsef2.png";
import sideLine3 from "../../images/Ellipsef3.png";
import sideLinem1 from "../../images/Ellipsefm1.png";
import sideLinem2 from "../../images/Ellipsefm2.png";
// import appStore from "../../images/app-store-footer.png";
import playStore from "../../images/playstore1.png";
import iosStore from "../../images/app-store-footer.png";
const Footer = () => {
  const [clicked1, setClicked1] = useState(false);
  const [clicked2, setClicked2] = useState(false);
  const [clicked3, setClicked3] = useState(false);
  const contentEl = useRef();

  // const { question, answer } = faq;

  const handleToggle1 = () => {
    setClicked1((prev) => !prev);
    if (clicked1 === false || clicked2 === true || clicked3 === true) {
      setClicked1(true);
      setClicked2(false);
      setClicked3(false);
    }
  };
  const handleToggle2 = () => {
    setClicked2((prev) => !prev);
    if (clicked2 === false || clicked1 === true || clicked3 === true) {
      setClicked1(false);
      setClicked2(true);
      setClicked3(false);
    }
  };
  const handleToggle3 = () => {
    setClicked3((prev) => !prev);
    if (clicked3 === false || clicked2 === true || clicked1 === true) {
      setClicked1(false);
      setClicked2(false);
      setClicked3(true);
    }
  };
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);
  return (
    <section className="footer">
      {screenWidth > 950 ? (
        <div>
          <img className="s-d-3" src={sideLine3} alt="" />
          <div className="ellipse-bg1">
            <div className="footer-heading">
              <div className="footer-logo">
                <div>
                  <img src={footerLogo} alt="" />
                </div>
                <span>PayNav</span>
              </div>
              <div className="footer-heading-item">
                <div className="footer-heading-items">Services</div>
                <div className="footer-heading-items">Legal</div>
                <div className="footer-heading-items">Follow us on</div>
              </div>
            </div>
            <div className="footer-body">
              <ul className="contact-info">
                <li>Hemantika Tech Solution Pvt Ltd</li>
                <li><a href="tel:+917569857929">+917569857929</a></li>
                <li>#2 B, 1st Floor,16th Cross, Vittal Nagar, 50FT Road, Kumaraswamy Layout 2nd Stage,Bangalore, Karnataka, India, 560078</li>
              </ul>
              <ul>
                <li>
                  <a href="#main-page">Home</a>
                </li>
                <li>
                  <a href="mailto:info@paynav.co">Contact Us</a>
                </li>
                <li>
                  <a href="mailto:careers@paynav.co">Careers</a>
                </li>
                <li>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.paynav.viaflutter.paynav_for_all"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Apps
                  </a>
                </li>
              </ul>
              <ul>
                <li>
                  <a
                    href="https://blush-port-8ff.notion.site/PayNav-Privacy-Policy-614d3f24d8274acdb5c11088527b83de"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy
                  </a>
                </li>
                <li>
                  <a
                    href="https://blush-port-8ff.notion.site/PayNav-Terms-of-use-359a764743b641ca814190dfcb4e998f"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms
                  </a>
                </li>
              </ul>
              <div className="social-icons">
                <div className="upper-icon">
                  <div className="insta">
                    <a
                      href="https://www.instagram.com/paynav/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={insta} alt="" />
                    </a>
                  </div>

                  <div className="facebook">
                    <a
                      href="https://www.facebook.com/paynav.co"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={facebook} alt="" />
                    </a>
                  </div>
                  <div className="twitter">
                    <a
                      href="https://twitter.com/paynavco"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={twitter} alt="" />
                    </a>
                  </div>
                </div>
                <div className="lower-icon">
                  <div className="linkedin">
                    <a
                      href="https://www.linkedin.com/company/paynav/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={linkedin} alt="" />
                    </a>
                  </div>
                  {/* <div className="telegram">
                      <a
                          href="https://www.linkedin.com/company/paynav/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                        <img src={telegram} alt="" />
                        </a>
                      </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="ellipse-bg2"></div>
          <div className="ellipse-bg3">
            <img className="s-d-1" src={sideLine1} alt="" />
            <img className="s-d-2" src={sideLine2} alt="" />

            <p className="copy">
              © 2023 PayNav | All rights reserved | Made with Golden 💛
            </p>
          </div>
        </div>
      ) : (
        //footer mobile
        <div className="footer-mobile">
          <img src={sideLinem1} className="s-d-l-m1" alt="" />
          <img src={sideLinem2} className="s-d-l-m2" alt="" />
          <div className="ellipse-bg4">
            <div className="footer-heading-m">
              <div className="footer-logo">
                <div>
                  <img src={footerLogo} alt="" />
                </div>
                <span>PayNav</span>
              </div>
              <div className="contact-info-m">
                <li>Hemantika Tech Solution Pvt Ltd</li>
                <li><a href="tel:+917569857929">+917569857929</a></li>
                <li>#2 B, 1st Floor,16th Cross, Vittal Nagar, 50FT Road, Kumaraswamy Layout 2nd Stage,Bangalore, Karnataka, India, 560078</li>
              </div>
            </div>
           
            <div className="footer-heading-item1">
              <li className={`accordion_item ${clicked1 ? "active" : ""}`}>
                <button className="button" onClick={handleToggle1}>
                  Services
                  <span className="control">{clicked1 ? "—" : "+"} </span>
                </button>

                <div
                  ref={contentEl}
                  className="answer_wrapper"
                  style={
                    clicked1 ? { height: "fit-content" } : { height: "0px" }
                  }
                >
                  <div className="answer">
                    <li>
                      <a href="#main-page">Home</a>
                    </li>
                    <li>
                      <a href="mailto:info@paynav.co">Contact Us</a>
                    </li>
                    <li>
                      <a href="mailto:careers@paynav.co">Careers</a>
                    </li>
                    <li>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.paynav.viaflutter.paynav_for_all"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Apps
                      </a>
                    </li>
                  </div>
                </div>
              </li>
              <li className={`accordion_item ${clicked2 ? "active" : ""}`}>
                <button className="button" onClick={handleToggle2}>
                  Legal
                  <span className="control">{clicked2 ? "—" : "+"} </span>
                </button>

                <div
                  ref={contentEl}
                  className="answer_wrapper"
                  style={
                    clicked2 ? { height: "fit-content" } : { height: "0px" }
                  }
                >
                  <div className="answer">
                    <li>
                      <a
                        href="https://blush-port-8ff.notion.site/PayNav-Privacy-Policy-614d3f24d8274acdb5c11088527b83de"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://blush-port-8ff.notion.site/PayNav-Terms-of-use-359a764743b641ca814190dfcb4e998f"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms
                      </a>
                    </li>
                  </div>
                </div>
              </li>
              <li className={`accordion_item ${clicked3 ? "active" : ""}`}>
                <button className="button" onClick={handleToggle3}>
                  Connect
                  <span className="control">{clicked3 ? "—" : "+"} </span>
                </button>

                <div
                  ref={contentEl}
                  className="answer_wrapper"
                  style={
                    clicked3 ? { height: "fit-content" } : { height: "0px" }
                  }
                >
                  <div className="answer">
                    <div className="social-icons">
                      <div className="insta">
                        <a
                          href="https://www.instagram.com/paynav/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={insta} alt="" />
                        </a>
                      </div>

                      <div className="facebook">
                        <a
                          href="https://www.facebook.com/paynav.co"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={facebook} alt="" />
                        </a>
                      </div>
                      <div className="twitter">
                        <a
                          href="https://twitter.com/paynavco"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={twitter} alt="" />
                        </a>
                      </div>
                      <div className="linkedin">
                        <a
                          href="https://www.linkedin.com/company/paynav/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={linkedin} alt="" />
                        </a>
                      </div>
                      {/* <div className="telegram">
                      <a
                          href="https://www.linkedin.com/company/paynav/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                        <img src={telegram} alt="" />
                        </a>
                      </div> */}
                    </div>
                  </div>
                </div>
              </li>
            </div>
            <div className="store-link">
              <div className="app-store-link">
                <a
                  href="https://apps.apple.com/us/app/paynav/id1668807648"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  
                  <img src={iosStore} alt="" />
                </a>
              </div>

              <div className="play-store-link">
                <a
                  href="https://play.google.com/store/apps/details?id=com.paynav.viaflutter.paynav_for_all"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  
                  <img src={playStore} alt="" />
                </a>
              </div>
            </div>
            <p className="copy">
              © 2023 PayNav | All rights reserved | Made with Golden 💛
            </p>
          </div>
          <div className="ellipse-bg5"></div>
        </div>
      )}
    </section>
  );
};

export default Footer;
