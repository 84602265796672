import React, {useEffect} from 'react'
import './Explore.css'
import Aos from "aos"
import "aos/dist/aos.css"
import smart from '../../images/smart.png'
import explore from '../../images/explore.png'
import exploreCard from '../../images/explore-card.png' 
import exploreWallet from '../../images/explore-wallet.png' 
import exploreBag from '../../images/explore-bag.png' 
import arch from '../../images/arch.png'
import archBorder from '../../images/arch-border.png'
import archBorder2 from '../../images/arch-border2.png'
import starBlue from '../../images/Star-blue.png'
import starGold from '../../images/Star-gold.png'
import circle from '../../images/explore-circle.png'
import circle2 from '../../images/explore-circle2.png'
const Explore = () => {
  useEffect(()=>{
    Aos.init({duration:1000});
  })
  return (
    <>
     <section className='explore'>
      <div className='explore-upper'>
      <div className="explore-heading">
      <img className='smart' src={smart} alt="" />
        <span>EXPLORE</span>
        <h1>WHY IT’S SMART</h1>
        <p>Ways you can use PayNav, Here are a few we think are fun right now:</p>
      </div>
      <div className="explore-body">
      <div className='explore-icons'>
      <img className='explore-arch arch' src={arch} alt="" />
        <img className='explore-arch-border arch' src={archBorder} alt="" />
          <img src={starBlue} alt="" className="star-blue" />
          <img src={starGold} alt="" className="star-gold" />
          <img src={circle} alt="" className="explore-circle" />
          <img src={circle2} alt="" className="explore-circle2" />
          <img className='explore-arch-border2 arch' src={archBorder2} alt="" />
      </div>
       <img  className='explore-phone'src={explore} alt="" />
      </div>
      </div>
        <div className="explore-footer">
          <div className="explore-footer-1" data-aos="fade-right">
            <div><img src={exploreBag} alt="" /></div>
          <h1>Earn GOLD on spending</h1>
         
            <p>Shop in PayNav app and earn GOLD Back when you make a purchase. Get a physical GOLD coin or withdraw cash when you saved enough.</p>
           
          </div>
          <div className="explore-footer-2" data-aos="fade-up">
            <div>
            <img src={exploreWallet} alt="" />
            </div>
            <h1>Save in 24k GOLD </h1>
            <p>Save money in Gold which yields 9-11*% annualised return, Invest spare change left after a expense, invest daily and save for Major Goals.</p>
          </div>
          <div className="explore-footer-3" data-aos="fade-down">
            <div><img src={exploreCard} alt="" /></div>
            <h1>Card that gives GOLD </h1>
            <p>Choose to get 0.5-1.5% flat GOLD back  on your purchase using this Debit Card, tag it to your existing bank account.</p>
          </div>
        </div>
        {/* <div className="slanted"></div> */}
    </section>
    </>
  )
}

export default Explore