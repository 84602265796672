import React, {useEffect, useRef, useState} from "react";
import "./Gateway.css"
import iphone13 from '../../images/iPhone 13 Pro Max - Silver - Portrait.png'
import gatewayBook from '../../images/gateway-book.png'
import gatewayReward from '../../images/gateway-reward.png'
import gatewayReward2 from '../../images/gateway-reward2.png'
import gatewayPercentage from '../../images/gateway%.png'
import gatewayPercentage2 from '../../images/gateway%2.png'
import gatewayOyo from '../../images/gateway-oyo-rooms.png'
import playStore from "../../images/playstore1.png";
import iosStore from "../../images/ios-icon.png"
const Gateway = () => {
    const refOne = useRef(null)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const [dropDown, setDropDown] = useState(false)
    const toggleNavs = () => {
      setDropDown(!dropDown)
    }
  
    const handleClickOutside = (event) => {
      if (refOne.current && !refOne.current.contains(event.target) && !event.target.matches('.footer-btn')) {
        setDropDown(false);
      }
  
    }
    useEffect(()=>{
      document.addEventListener("click", handleClickOutside, true)
      const changeWidth = () => {
        setScreenWidth(window.innerWidth);
      }
  
      window.addEventListener('resize', changeWidth)
  
      return () => {
        window.removeEventListener('resize', changeWidth);
        document.removeEventListener('click', handleClickOutside, true);
    }
    })
  return (
    <section className="gateway">
       <div className="gateway-body">
       <div className="gateway-l">
        <div className="big-txt">
            <span>The <span className='special-gateway-text'>Golden</span> Gateway</span>
            <span>to Financial Viability</span>
        </div>
        <div className="small-txt">
            <span>Earn on every spend, Invest spare change, 
create long term goals,bank smarter, 
earn bonus investments, and more!</span>
        </div>
       </div>
        <div className="gateway-r">
            <div className='gateway-ractangel1'>
                <img src={gatewayBook} alt="" className="gateway-book" />
                <img src={gatewayPercentage} alt="" className="gateway-percent" />
                <img src={gatewayReward} alt="" className="gateway-reward" />
            </div>
           <div className='iphone13'> <img src={iphone13} alt="" /></div>
           <div className='gateway-ractangel2'>
           <img src={gatewayOyo} alt="" className="gateway-oyo" />
                <img src={gatewayPercentage2} alt="" className="gateway-percent2" />
                <img src={gatewayReward2} alt="" className="gateway-reward2" />
           </div>
        </div>
       </div>
       <div className="gateway-footer">
            <div className="footer-head">
                <span>Get The App For Free</span>
                <span>And Start Now</span>
            </div>
            {screenWidth>768 ?  <div onClick={toggleNavs} className='footer-btn'>
            Download The App {dropDown && (
                  <div ref={refOne} className="play-store">
                  <div className="play-store-icon">
                  <a href="https://play.google.com/store/apps/details?id=com.paynav.viaflutter.paynav_for_all" target="blank"><img src={playStore} alt="" /></a>
                  </div>
                  <div className="ios-store">
                    <a href="https://apps.apple.com/us/app/paynav/id1668807648" target="_blank"><img src={iosStore} alt="" /></a>
                  </div>
                  </div>
      )}
                
            </div>:  <a href="https://paynavforall.page.link/download" target="blank"><div className='footer-btn'>
               
                    Download The App
                
            </div></a>}
       </div>
        
    </section>
  )
}

export default Gateway